import { MenuItem } from "../constants/menu.constants";
import { Dish } from "../dish/dish";
import { Price } from "../price/price";
import { EntityIdField } from "../entity";

export type MenuItemList = (Menu | Dish)[];

export interface Menu extends EntityIdField {
  name: string | any;
  menu?: string; // parent menu id
  business: string; // business id
  description?: string | any;
  type: MenuItem.MENU;
  items: MenuItemList;
  prices: Price[];
  position?: number;
  hidden?: boolean;
}
